import React, { Component } from 'react'
import styles from './pagehead.module.scss'
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';

class PageHead extends Component {
  render() {
    return (
      <div className={styles.pageHead}>
        <div className={styles.container}>
           <div className={styles.content}>
            <div className={styles.text}>
            <Zoom>
              <h1>
                {this.props.head}
              </h1>
            </Zoom>
            <Fade>
              <h2>
               {this.props.text}
              </h2>
            </Fade>
            </div>
        </div>
      </div>
      </div>
    )
  }
}

export default PageHead
