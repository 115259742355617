import React from 'react';
import styles from './value.module.scss';
import Collaboration from '../../images/icons/Collaboration.svg'
import Growth from '../../images/icons/Growth.svg'
import Innovation from '../../images/icons/Innovation.svg'
import Ownership from '../../images/icons/Ownership.svg'


const index = () => {
  return (
    <div>
       <div className={styles.head}>
        <h3>
          Our Core Values
          <hr></hr>
        </h3>
        <p>The values that define us as a company.</p>
      </div>
      <div className={styles.values}>
        <div className={styles.valuesCard}>
          <div className={styles.image}>
            <img src={Innovation}/>
          </div>
          <h4>
            Innovation
          </h4>
          <p>
            From the technologies and processes of building solutions to how we engage our clients to our typical day at work, we encourage new and better ways of doing things. Innovation is crucial to our continued success.
          </p>
        </div>
         <div className={styles.valuesCard}>
          <div className={styles.image}>
            <img src={Growth}/>
          </div>
          <h4>
            Continuous Improvement
          </h4>
          <p>
            To achieve our grand vision of an organisation operating at a global impact level, our capacity and results must always improve.Hence, we always put programs in place to ensure our iQubers are always growing their skills and accelerating their efficiency.This extends to our clients and partners as we always seek to proactively improve our service delivery.
          </p>
        </div>
         <div className={styles.valuesCard}>
          <div className={styles.image}>
            <img src={Ownership}/>
          </div>
          <h4>
            Ownership
          </h4>
          <p>
            Every iQuber owns iQube and this ownership comes with a responsibility and commitment to excellence. Everyone is an important stakeholder and this mentality helps us approach our service delivery and partnerships with utmost responsibility.
          </p>
        </div>
         <div className={styles.valuesCard}>
          <div className={styles.image}>
            <img src={Collaboration}/>
          </div>
          <h4>
            Collaboration
          </h4>
          <p>
            We believe that everyone is uniquely equipped to contribute to our immediate and long term success. Building on ownership, we play our distinct parts as well as support our partners to win TOGETHER. It’s our belief that a win for the organisation is a win for our partners and a win for our clients is a win for the organisation.          </p>
        </div>
      </div>
    </div>
  );
}

export default index;
