import React from 'react'
import {
  graphql,
  StaticQuery
} from 'gatsby';
import styles from './team.module.scss'
function Team(props) {

  const team = props.data.allContentfulTeamMembers.edges
  return (
    <div>
      {/*Instruction:  When team members are available, uncomment the code below*/}


      {/* <div className={styles.head}>
        <h3>
          Meet Our Team
          <hr></hr>
        </h3>
        <p>Get to meet the wonderful people that make all those beautiful stuff you see.</p>
        
      </div>

      <div className={styles.teamlist}>
      {team.map(item => (
        
        <div className={styles.teamlistItem}>
          <div className={styles.teamMember}>
            <div className={styles.teamMemberImage}>

              <img src={item.node.image.file.url} alt={item.node.name}/>
            </div>
            <p className={styles.teamMemberName}>
              {item.node.name}
            </p>
            <div className={styles.teamMemberRole}>{item.node.title}</div>
          </div>
        </div>
      ))}
      </div> */}
    </div>
  )
}


export default () => (
  <StaticQuery
    query={graphql`
      query TeamMembers {
        allContentfulTeamMembers {
          edges {
            node {
              image {
                file {
                  url
                }
              }
              name
              title
            }
          }
        }
      }

      
    `}
    render={data => (
      <Team 
        data={data}
      />
    )}
  />
)
