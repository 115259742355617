import React from "react"
import styles from "./aboutText.module.scss";
import Mayowa from './../../images/Mayowa-Ayodeji.jpg'
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';

export default function AboutText() {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <div className={styles.head}>
          <hr></hr>
          <h2>
            From our business lead. 
          </h2>
           <p>
           Research and Development is the core of what we do at iQube Labs. We apply our collective wealth of experience in Computer Science and Engineering to develop Innovations in products or solutions that will accelerate the positive impact of people and businesses.
          </p>
          <p>
            Our service offerings include the use of Software development, Data Science, intelligent value-added services and trainings. We have served several individuals and businesses with solutions across sectors like Education, Venture Capital, Health, Public Service and Governance, Entertainment, Telecommunications, Religion, Retail and logistics. We have currently partnered with an NGO called Akin Fadeyi Foundation to solve the problem of transparency, feedback and access in public service delivery for Nigerian agencies towards fighting corruption. It is called Flag It, it is a web and mobile-based software.
          </p>
        </div>
          <div className={styles.main}>
          <Slide>
            <div className={styles.image}>
              <img src={Mayowa} alt="Mayowa Ayodeji"/>
            </div>
          </Slide>
            <div className={styles.mainText}>
            <Fade top>
              <p>
                We do not just build solutions for other businesses, we also develop in-house products that get into the market to deliver value. We have HRP; an electronic medical record software currently deployed by Medifix NG, both came out of iQube. Redbank NG is also a service from iQube Labs deployed by Medifix, it uses shortcodes and a mobile application to connect Nigerians in need of blood to their donors swiftly. My Perfect Weight is a mobile application that provides its users with professional recommendations towards improved wellbeing, it is now a product of HealthFacts NG, another startup that came out of iQube Labs. We have many more impactful products being developed by our R&D team, watch out
              </p>
              <p>
                As a company, we believe young Africans can do so much where passion and talent are allowed to thrive.Majority of our talent force started out as engineering interns and were groomed through rigour and close mentoring.We have a culture of continuous learning and development and everyone(including the Business Lead) always has a personal development track they’ re running concurrently with their duties.We run this model since the inception and have stellar alumni doing magic in their current fields.We have now condensed this experience to create a Technical Academy that offers a unique learning experience to individuals to want to develop relevant skills in technology, it is called iQubeTT(iQube Tech Trainings).
              </p>
              <p>
                Finally, we are an organisation that is motivated by challenges and we love to use technology to make a positive impact.
              </p>
            </Fade>
            </div>
        </div>
      </div>
    </div>
  )
}
