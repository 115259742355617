import React from "react"
import Layout from "../components/layout"
import AboutText from '../components/AboutText/AboutText'
import PageHead from "../components/PageHead/PageHead";
import Team from "../components/Team";
import SEO from "../components/seo";
import Value from "../components/Value"

const About = () => {
 return (
  <Layout>
    <SEO
      title="iQubeLabs | About Us"
      description="Learn about what makes us a unique technology company"
    />
    <PageHead
      head="About Us"
      text=" Our grand vision at iQube is to build an organisation that readily solves relevant African problems at scale using technology."
    />
    <AboutText />
    <Value />
    <Team />
  </Layout>
 )
}

export default About